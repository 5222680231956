import React, { memo } from "react";
import styles from "../styles/components/shortInput.module.scss";

const SubChecklist = ({
  items,
  subItems,
  placeholder,
  subPlaceholder,
  checkedItems,
  setCheckedItems,
}) => {
  const handleCheck = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCheckedItems((prevCheckedItems) => [
        ...prevCheckedItems,
        { item: value, subItems: [] },
      ]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item.item !== value));
    }
  };

  const handleSubCheck = (mainItem, event) => {
    const { value, checked } = event.target;

    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.map((item) =>
        item.item === mainItem
          ? {
              ...item,
              subItems: checked
                ? [...item.subItems, value]
                : item.subItems.filter((subItem) => subItem !== value),
            }
          : item
      )
    );
  };

  return (
    <div className={styles.shortInputContainer}>
      <label>{placeholder + " :"}</label>
      <ul style={{ listStyleType: "none" }}>
        {items.map((item, index) => (
          <li key={index}>
            <input
              type="checkbox"
              value={item}
              onChange={handleCheck}
              checked={checkedItems.some(
                (checkedItem) => checkedItem.item === item
              )}
            />
            <span> {item}</span>
            {checkedItems.some((checkedItem) => checkedItem.item === item) && (
              <div className={styles.shortInputContainer}>
                <label>{subPlaceholder + " :"}</label>
                <ul style={{ listStyleType: "none" }}>
                  {subItems.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      <input
                        type="checkbox"
                        value={subItem}
                        onChange={(event) => handleSubCheck(item, event)}
                        checked={
                          checkedItems
                            .find((checkedItem) => checkedItem.item === item)
                            ?.subItems.includes(subItem) || false
                        }
                      />
                      <span> {subItem}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(SubChecklist);
