import styles from "../styles/screens/home.module.scss";
import TopNav from "../components/TopNav";
import KycApproval from "../cards/Kyc";

export default function KycScreen({ handleSidebarOpen }) {
  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <KycApproval />
        </div>
      </div>
    </div>
  );
}
