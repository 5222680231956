import { initializeApp } from "firebase/app";
import firebaseConfigDev from "./firebaseConfigDev.json";
import firebaseConfig from "./firebaseConfig.json";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const env = process.env.REACT_APP_ENV;
const FIREBASE = env === "DEV" ? firebaseConfigDev : firebaseConfig;
const RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  "6LflRAkqAAAAABJRjM_ptsT29mMSAXZgZxVhLU34";

const firebase = {
  apiKey: FIREBASE.apiKey,
  authDomain: FIREBASE.authDomain,
  projectId: FIREBASE.projectId,
  storageBucket: FIREBASE.storageBucket,
  messagingSenderId: FIREBASE.messagingSenderId,
  appId: FIREBASE.appId,
};
const app = initializeApp(firebase);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

export default app;
