import React, { memo, useCallback, useState } from "react";
import styles from "../styles/cards/addCategory.module.scss";
import api from "../api/api";
import SmallButton from "../components/SmallButton";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import userIcon from "../assets/images/userIcon.svg";
import LoadingAnim from "../components/LoadingAnim";

const validFileType = ["image/png", "image/jpg", "image/jpeg"];

const AddCategory = ({ setIsVisible, isUpdate ,setIsUpdate }) => {
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [file, setFile] = useState(null);

  const handleSave = useCallback(async () => {
    if (!name || !file)
      return setNotification({
        ...{ text: "All field are required", type: "error" },
      });
    const formData = new FormData();
    formData.append("product-category-images", file);
    formData.append("name", name);

    setLoading(true);
    const res = await api.addProductCategory(formData);
    if (!res) return setLoading(false);
    if (res.status === 200) {
      setNotification({
        ...{ text: res.data.message, type: "success" },
      });
      setIsUpdate(!isUpdate)
      setIsVisible(false);
      setLoading(false);
      return;
    } else {
      setNotification({ ...{ text: res.data.message, type: "error" } });
      setLoading(false);
      return;
    }
  }, [name, file]);

  const handleNameChange = useCallback((e) => {
    setName((pre) => (pre = e.target.value));
  }, []);

  const handleFileChange = useCallback((e) => {
    let newFile = e.target.files[0];
    if (newFile) {
      if (!validFileType.find((type) => type === newFile.type)) {
        setNotification({
          text: "File must be in jpg, png or jpeg format",
          type: "error",
        });
        setFile(null);
        return;
      } else if (newFile.size > 1000000) {
        setNotification({
          text: "File size should be less than 1MB",
          type: "error",
        });
        setFile(null);
        return;
      }
      setFile(prev => prev=newFile);
    }
  }, []);

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Category</span>
      </div>
      <div className={styles.cardDropDowns}>
        <ShortInput
          action={handleNameChange}
          placeholder="Category name"
          icon={userIcon}
        />
        <ShortInput
          type={"file"}
          action={handleFileChange}
          placeholder="Category image"
          icon={userIcon}
        />
      </div>

      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Add" onClick={handleSave} />
        <SmallButton
          type="secondary"
          text="Cancel"
          onClick={() => setIsVisible(false)}
        />
      </div>
    </div>
  );
};

export default memo(AddCategory);
