import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import styles from "../styles/cards/addCard.module.scss";
import miscsStyles from "../styles/cards/miscs.module.scss";

import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";
import QuantityInput from "../components/NumberInput";
import Button from "../components/Button";
import api from "../api/api";

export default function AddQuotes() {
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [screens, setScreens] = useState([]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const res = await api.updateScreenSettings({
        screens,
      });
      setLoading(false);
      setNotification({
        type: "success",
        text: "Settings saved successfully",
      });
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    } catch (err) {
      setLoading(false);
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    }
  };

  const getSettings = async () => {
    try {
      setLoading(true);
      const result = await api.getScreenSettings();
      setScreens(result?.screens);
      setLoading(false);
    } catch (error) {
      console.log("Error getting settings");
    }
  };

  useEffect(() => {
    getSettings();
  }, []);
  return (
    <div className={styles.cardContainer}>
      <Notification data={notification} />
      <LoadingAnim loading={loading} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Screen Settings</span>
      </div>
      <div className={miscsStyles.miscsContainer}>
        {screens &&
          screens.map((screen, index) => (
            <div key={index} className={miscsStyles.row}>
              <div className={miscsStyles.buttonRow}>
                <span className={styles.buttonText}>{screen.screenName}</span>
                <Switch
                  checked={screen.isVisible}
                  onChange={() => {
                    const updatedScreens = screens.map((s) => {
                      if (s._id === screen._id) {
                        return { ...s, isVisible: !s.isVisible };
                      }
                      return s;
                    });
                    setScreens(updatedScreens);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button text="Update" action={handleSave} />
      </div>
    </div>
  );
}
