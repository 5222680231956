import React, { memo } from "react";
import styles from "../styles/components/shortInput.module.scss";

const DropdownSelect = ({
  options,
  defaultValue,
  placeholder,
  action,
  icon,
  disabled,
}) => {
  return (
    <div className={styles.shortInputContainer}>
      <label>{placeholder + " :"}</label>
      <div className={styles.shortInputWrapper}>
        {icon && <img src={icon} alt="" />}
        <select
          onChange={action}
          defaultValue={defaultValue.value}
          disabled={disabled}
          style={{ border: "none" }}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default memo(DropdownSelect);
