import React from "react";
import styles from "../styles/components/dashboardCard.module.scss";

import userIcon from "../assets/images/userIcon.svg";
import goingDownIcon from "../assets/images/goingDown.svg";
import trendingIcon from "../assets/images/trending.svg";

export default function DashboardCard({ title, value, type, percentage }) {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.iconContainer}>
        <img src={userIcon} alt="" />
      </div>
      <div className={styles.cardContent}>
        <div className={styles.textContent}>
          <span className={styles.title}>{title}</span>
          <span className={styles.value}>{value}</span>
        </div>
        <div className={styles.iconContent}>
          <img src={type === "up" ? trendingIcon : goingDownIcon} alt="" />
          <span
            className={`${styles.percentage} ${
              type === "up" ? styles.green : styles.red
            }`}
          >
            {percentage}
          </span>
        </div>
      </div>
    </div>
  );
}
