import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";
import TopNav from "../components/TopNav";
import GameList from "../cards/GameList";
import AddGame from "../cards/AddGame";

export default function GamesScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <AddGame
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <GameList
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
      </div>
    </div>
  );
}
