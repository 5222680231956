import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";

import userIcon from "../assets/images/userIcon.svg";
import pricingIcon from "../assets/images/pricin_blk.svg";
import api from "../api/api";

export default function PayoutList({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these users?")) return;
    let checked = tableData?.td.filter((td) => td.checked);
    // setLoading(true);
    // let res = await users.deleteBulk(checked);
    // if (res.message.includes("Deleted")) {
    //   setNotification({
    //     type: "success",
    //     text: "Users deleted successfully",
    //   });
    //   setEditRow(null);
    //   setLoading(false);
    //   setUpdateTrigger(!updateTrigger);
    //   return;
    // }
    // setNotification({
    //   type: "error",
    //   text: "Something went wrong",
    // });
    // setEditRow(null);
    // setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];
  const [tableData, setTableData] = useState({
    th: ["No.", "User Name", "Amount", "Payout State", "Date", "Process"],
    td: [],
  });

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const buttonAction = (data) => {
    console.log(data);
  };

  const handleNameChange = (e) => {
    if (!editRow) return;
    editRow.name = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleEmailChange = (e) => {
    if (!editRow) return;
    editRow.email = e.target.value;
    setEditRow({ ...editRow });
  };

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  //   setTableOptions({ ...tableOptions, date: e.target.value });
  // };

  const handleFilterEmailChange = (e) => {
    setFilterEmail(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let res = await api.processPayout(editRow.id);
    if (res.message) {
      setNotification({
        type: "success",
        text: "Payout updated successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?") === true) {
      // setLoading(true);
      // let res = await users.deleteUser({ id: editRow.id });
      // if (res.message.includes("Deleted")) {
      //   setNotification({
      //     type: "success",
      //     text: "User deleted successfully",
      //   });
      //   setEditRow(null);
      //   setLoading(false);
      //   return;
      // }
      // setNotification({
      //   type: "error",
      //   text: "Something went wrong",
      // });
      // setEditRow(null);
      // setLoading(false);
    }
  };

  useEffect(() => {
    console.log(editRow);
  }, [editRow]);

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const data = await api.getPayoutData(tableOptions);
      setCountTotal(data.totalCount);

      const sortedTd = data.PayoutRequests.map((item) => ({
        id: item?._id,
        checked: false,
        name: item?.userId?.username,
        email: item?.amount,
        subscription: item?.isProcessed ? "Processed" : "Not Processed",
        date: new Date(item?.timeRequested).toLocaleDateString(),
        transactionId: item?.payoutBatchId,
        button: item?.isProcessed
          ? { text: "View", type: "view" }
          : {
              action: buttonAction,
              text: "Process",
              type: "primary",
            },
      })).sort((a, b) => new Date(b.date) - new Date(a.date));

      setTableData({ ...tableData, td: sortedTd });
      setLoading(false);
    };

    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        email: filterEmail,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Payout List</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            defaultValue={editRow?.name}
            placeholder="Username"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            defaultValue={editRow?.email}
            placeholder="Amount"
            icon={pricingIcon}
            action={handleFilterEmailChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleNameChange={handleNameChange}
                handleEmailChange={handleEmailChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ editRow, handleNameChange, handleEmailChange }) {
  return (
    <>
      <ShortInput
        defaultValue={editRow?.name}
        placeholder="Name"
        icon={userIcon}
        action={handleNameChange}
        disabled={editRow?.button?.type === "view"}
      />
      <ShortInput
        defaultValue={editRow?.email}
        placeholder="Amount"
        icon={pricingIcon}
        action={handleEmailChange}
        disabled={editRow?.button?.type === "view"}
      />
      {editRow?.button?.type === "view" && (
        <ShortInput
          defaultValue={editRow?.transactionId}
          placeholder="Transaction ID"
          action={handleEmailChange}
          disabled={true}
        />
      )}
    </>
  );
}
