import React from "react";
import styles from "../styles/components/dropDown.module.scss";

import nextIcon from "../assets/images/nextIcon.svg";

export default function DropDown({ options, dark, action }) {
  return (
    <div
      className={
        dark
          ? `${styles.darkContainer} ${styles.dropDownContainer}`
          : styles.dropDownContainer
      }
    >
      <select className={styles.timeSelect} onChange={action}>
        {options.map((option, index) => (
          <option value={option.value} defaultChecked={index === 0} key={index}>
            {option.text}
          </option>
        ))}
      </select>
      <img src={nextIcon} alt="" className={styles.arrowDown} />
    </div>
  );
}
