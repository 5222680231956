import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";
import TopNav from "../components/TopNav";
import ComplaintList from "../cards/ComplaintList";

export default function MatchesScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <ComplaintList updateTrigger={updateTrigger} setUpdateTrigger={setUpdateTrigger}/>
        </div>
      </div>
    </div>
  );
}
