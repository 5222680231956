import axios from "axios";
import config from "./config/config";
import { getAppCheckToken } from "./firebaseConfig/appCheck";

const axiosPrivate = axios.create({
  baseURL: config.serverUrl,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

axiosPrivate.interceptors.request.use(
  async (config) => {
    const token = await getAppCheckToken();
    if (token) {
      config.headers["X-Firebase-AppCheck"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosPrivate };
