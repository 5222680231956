import React from "react";
import styles from "../styles/components/loadingAnim.module.scss";
import { ColorRing } from "react-loader-spinner";

export default function LoadingAnim({ loading }) {
  if (loading) {
    return (
      <div className={styles.loadingAnimContainer}>
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#797EF6", "#4BDEDD", "#17A7EC", "#1E2F97", "#3A557A"]}
        />
      </div>
    );
  }
  return <></>;
}
