import React, { useCallback, useState } from "react";
import styles from "../styles/pages/dashboard.module.scss";
import Sidebar from "../components/Sidebar";
import CategoryScreen from "../screens/Categories.screen";

export default function Categories({ setLoggedIn }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarOpen = useCallback((e) => {
    setSidebarOpen(!sidebarOpen);
  }, [sidebarOpen]);

  return (
    <div className={styles.wrapper}>
      <Sidebar
        setLoggedIn={setLoggedIn}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <CategoryScreen handleSidebarOpen={handleSidebarOpen} />
    </div>
  );
}
