import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";
import TopNav from "../components/TopNav";
import TournamentList from "../cards/TournamentList";

export default function TournamentsScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <TournamentList updateTrigger={updateTrigger} setUpdateTrigger={setUpdateTrigger}/>
        </div>
      </div>
    </div>
  );
}
