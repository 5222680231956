import React, { memo } from "react";
import styles from "../styles/components/shortInput.module.scss";

const ShortInput = ({
  defaultValue,
  placeholder,
  action,
  icon,
  type,
  disabled,
  url,
  urlText,
}) => {
  return (
    <div className={styles.shortInputContainer}>
      <label>{placeholder + " :"}</label>
      <div className={styles.shortInputWrapper}>
        {icon && <img src={icon} alt="" />}
        {!url && (
          <input
            type={type || "text"}
            onChange={action}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        )}
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {urlText}
          </a>
        )}
      </div>
    </div>
  );
};

export default memo(ShortInput);
