import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";
import userIcon from "../assets/images/userIcon.svg";
import emailIcon from "../assets/images/emailIcon.svg";
import api from "../api/api";
import SmallButton from "../components/SmallButton";
import ShowMatchUsers from "./ShowMatchUsers";
import DropdownSelect from "../components/DropdownSelect";
import { useCallback } from "react";
import ImageUpload from "../components/ImageUpload";

export default function GameList({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [players, showPlayers] = useState(false);
  const [imageData, setImageData] = useState({});

  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these users?")) return;
    // let checked = tableData?.td.filter((td) => td.checked);
    // setLoading(true);
    // let res = await users.deleteBulk(checked);
    // if (res.message.includes("Deleted")) {
    //   setNotification({
    //     type: "success",
    //     text: "Users deleted successfully",
    //   });
    //   setEditRow(null);
    //   setLoading(false);
    //   setUpdateTrigger(!updateTrigger);
    //   return;
    // }
    // setNotification({
    //   type: "error",
    //   text: "Something went wrong",
    // });
    // setEditRow(null);
    // setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];
  const [tableData, setTableData] = useState({
    th: ["No.", "Name", "Consoles", "Game Types", "Rounds", "Action"],
    td: [],
  });

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const buttonAction = (data) => {
    // console.log(data);
  };

  const handleStatusChange = useCallback(
    (e) => {
      if (!editRow) return;
      editRow.matchStatus = e.target.value;
      setEditRow({ ...editRow });
    },
    [editRow]
  );

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  //   setTableOptions({ ...tableOptions, date: e.target.value });
  // };

  const handleFilterEmailChange = (e) => {
    setFilterEmail(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    let res = await api.changeGameImage(imageData);
    if (res.imgUrl) {
      setNotification({
        type: "success",
        text: "Game Image Updated",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Upload Failed",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?") === true) {
      // setLoading(true);
      // let res = await users.deleteUser({ id: editRow.id });
      // if (res.message.includes("Deleted")) {
      //   setNotification({
      //     type: "success",
      //     text: "User deleted successfully",
      //   });
      //   setEditRow(null);
      //   setLoading(false);
      //   return;
      // }
      // setNotification({
      //   type: "error",
      //   text: "Something went wrong",
      // });
      // setEditRow(null);
      // setLoading(false);
    }
  };

  const handleOnPlayers = async () => {
    showPlayers(!players);
  };

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const data = await api.getGameData(tableOptions);
      setCountTotal(data?.totalCount);
      tableData.td = [];
      data.games.map((game, index) => {
        tableData.td.push({
          id: game._id,
          checked: false,
          gameName: game?.name,
          consoles: game?.consoles.map((item) => `${item.text}, `),
          gameTypes: game?.gameTypes.map((item) => `${item.text}, `),
          rounds: game?.rounds.map((item) => `${item.name}, `),
          gameImage: game?.image,
          objectKey: game?.objectKey,
          button: { action: buttonAction, text: "Update" },
        });
      });
      setLoading(false);
      setTableData({ ...tableData });
    };
    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        email: filterEmail,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Game List</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            defaultValue={editRow?.name}
            placeholder="Name"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            defaultValue={editRow?.email}
            placeholder="Email"
            icon={emailIcon}
            action={handleFilterEmailChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleStatusChange={handleStatusChange}
                handleImageChange={setImageData}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>

      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
      {players && (
        <ShowMatchUsers
          updateTrigger={players}
          setUpdateTrigger={() => showPlayers(false)}
          data={editRow}
        />
      )}
    </>
  );
}
export function Filters({ editRow, handleStatusChange, handleImageChange }) {
  return (
    <>
      <ShortInput
        defaultValue={editRow?.gameName}
        placeholder="Game Name"
        action={handleStatusChange}
      />
      <ImageUpload
        imageUrl={editRow?.gameImage}
        gameId={editRow?.id}
        objectKey={editRow?.objectKey}
        placeholder="Game Image"
        action={handleImageChange}
      />
    </>
  );
}
