import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Admins from "./pages/Admins";
import Settings from "./pages/Settings";
import Matches from "./pages/Matches";
import Tournaments from "./pages/Tournaments";
import Announcements from "./pages/Announcements";
import Complaints from "./pages/Complaints";
import Payouts from "./pages/Payouts";
import Categories from "./pages/Categories";
import Games from "./pages/Games";
import KYC from "./pages/Kyc";
import Transactions from "./pages/Transactions"

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) token = sessionStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    }
  }, []);

  if (!loggedIn) {
    if (window.location.pathname !== "/") window.location.href = "/";
    return (
      <LoginPage setLoggedIn={setLoggedIn} />
    );
  } else {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={<Dashboard setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/users"
              element={<Users setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/matches"
              element={<Matches setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/tournaments"
              element={<Tournaments setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/payouts"
              element={<Payouts setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/announcements"
              element={<Announcements setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/complaints"
              element={<Complaints setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/admins"
              element={<Admins setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/games"
              element={<Games setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/categories"
              element={<Categories setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/settings"
              element={<Settings setLoggedIn={setLoggedIn} />}
            />
            <Route path="/kyc" element={<KYC setLoggedIn={setLoggedIn} />} />
            <Route
              path="/transactions"
              element={<Transactions setLoggedIn={setLoggedIn} />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
