import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";
import SmallButton from "../components/SmallButton";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import userIcon from "../assets/images/userIcon.svg";
import LoadingAnim from "../components/LoadingAnim";
import api from "../api/api";
import Checklist from "../components/Checklist";
import SubChecklist from "../components/SubChecklist";

export default function AddGame({ updateTrigger, setUpdateTrigger }) {
  const [name, setName] = useState(null);
  const [provider, setProvider] = useState(null);
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState(null);

  const [selectedConsoles, setSelectedConsoles] = useState([]);
  const consoles = [
    "CROSS_PLAY",
    "CURRENT_GEN_CROSS_PLAY",
    "LAST_GEN_CROSS_PLAY",
    "PC",
    "CONSOLE_ONLY",
    "PS4",
    "PS5",
    "XBOX_ONE",
    "XBOX_X_S",
  ];

  const [selectedGameTypes, setSelectedGameTypes] = useState([]);
  const gameTypes = [
    "ONE_VS_ONE",
    "TWO_VS_TWO",
    "THREE_VS_THREE",
    "FOUR_VS_FOUR",
    "FIVE_VS_FIVE",
    "FFA",
  ];
  const [selectedRounds, setSelectedRounds] = useState([]);
  const rounds = [
    "BEST_1_OF_1",
    "BEST_1_OF_2",
    "BEST_2_OF_3",
    "BEST_3_OF_5",
    "BEST_5_OF_7",
  ];

  const teams = [
    "BR Kill Race",
    "Arena Kill Race",
    "BR Kill Challenge",
    "CDL Hardpoint",
    "CDLS & D",
    "Death Match",
    "Gunfight",
    "Snipers only",
    "BR Survival",
    "Resurgence Kill race",
    "Resurgence Kill Challenge",
    "Regular Team",
    "MUT",
    "RegularTeams",
    "FUT",
    "Kill Race",
    "Zero Build",
    "Box Fight",
    "Zone Wars",
  ];
  const handleSave = async () => {
    if (!name) return;
    setLoading(true);
    let data = {
      name,
      provider,
      consoles: selectedConsoles,
      rounds: selectedRounds,
      gameTypes: selectedGameTypes.map((item) => ({
        type: item.item,
        teams: item.subItems,
      })),
    };
    const res = await api.addGame(data);
    if (!res) return setLoading(false);
    if (res.message) {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Announcement sent successfully", type: "success" },
      });
      setLoading(false);
      return;
    }
    if (!res.success) {
      setNotification({ ...{ text: res.message, type: "error" } });
      setLoading(false);
      return;
    }
    setNotification({ ...{ text: "Something went wrong", type: "error" } });
    setLoading(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleProviderChange = (e) => {
    setProvider(e.target.value);
  };
  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Game</span>
      </div>
      <div className={styles.cardDropDowns}>
        <ShortInput
          action={handleNameChange}
          placeholder="Name"
          icon={userIcon}
        />
        <ShortInput
          action={handleProviderChange}
          placeholder="Provider"
          icon={userIcon}
        />
        <Checklist
          items={consoles}
          placeholder="Consoles"
          checkedItems={selectedConsoles}
          setCheckedItems={setSelectedConsoles}
        />
        <SubChecklist
          items={gameTypes}
          placeholder="Game Types"
          checkedItems={selectedGameTypes}
          setCheckedItems={setSelectedGameTypes}
          subItems={teams}
          subPlaceholder="Teams"
        />
        <Checklist
          items={rounds}
          placeholder="Rounds"
          checkedItems={selectedRounds}
          setCheckedItems={setSelectedRounds}
        />
      </div>

      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Add" onClick={handleSave} />
      </div>
    </div>
  );
}
