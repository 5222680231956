import { memo } from "react";
import styles from "../styles/components/categoryCard.module.scss";
function CategoryCard({ category, onSelect, isSelected }) {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.input}>
        <input
          type="checkbox"
          onChange={() => onSelect(category._id)}
          checked={isSelected}
        />
      </div>
      <div className={styles.image}>
        <img src={category.imageUrl} alt="Image" />
      </div>
      <div className={styles.title}>
        <p>{category.name}</p>
      </div>
    </div>
  );
}

export default memo(CategoryCard);
