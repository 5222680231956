import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";
import SmallButton from "../components/SmallButton";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import emailIcon from "../assets/images/emailIcon.svg";
import userIcon from "../assets/images/userIcon.svg";
import LoadingAnim from "../components/LoadingAnim";
import api from "../api/api";

export default function PostAnnouncement({ updateTrigger, setUpdateTrigger }) {
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [audience, setAudience] = useState(null);
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState(null);

  const handleSave = async () => {
    if (!title || !body) return;
    setLoading(true);
    let data = {
      title,
      body,
      audience,
    };
    const res = await api.postAnnouncement(data);
    if (!res) return setLoading(false);
    if (res.message) {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Announcement sent successfully", type: "success" },
      });
      setLoading(false);
      return;
    }
    if (!res.success) {
      setNotification({ ...{ text: res.message, type: "error" } });
      setLoading(false);
      return;
    }
    setNotification({ ...{ text: "Something went wrong", type: "error" } });
    setLoading(false);
  };

  const handleNameChange = (e) => {
    setTitle(e.target.value);
  };
  const handleEmailChange = (e) => {
    setBody(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setAudience(e.target.value);
  };

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Post Announcements</span>
      </div>
      <div className={styles.cardDropDowns}>
        <ShortInput
          action={handleNameChange}
          placeholder="Title"
          icon={userIcon}
        />
        <ShortInput
          action={handleEmailChange}
          placeholder="Body"
          icon={emailIcon}
        />
        <ShortInput
          action={handlePasswordChange}
          placeholder="Audience"
          icon={emailIcon}
        />
      </div>

      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Send" onClick={handleSave} />
      </div>
    </div>
  );
}
