import React from "react";
import styles from "../styles/components/button.module.scss";

export default function Button({ text, action, disabled, type }) {
  const btnStyle =
    type === "view"
      ? `${styles.view} ${styles.buttonContainer}`
      : `${styles.buttonContainer}`;

  return (
    <div className={btnStyle}>
      <button onClick={action} disabled={disabled}>
        {text}
      </button>
    </div>
  );
}
