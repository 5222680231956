import React, { useState, useEffect, memo } from "react";
import styles from "../styles/components/topNav.module.scss";

import TextInput from "./TextInput";

import bellIcon from "../assets/images/bell.svg";
import profileIcon from "../assets/images/profileIcon.jpg";

const TopNav = ({ handleSidebarOpen }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.topNav}>
      {isMobile && (
        <button className={styles.menuButton} onClick={handleSidebarOpen}>
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </button>
      )}
      <div className={styles.searchboxWrapper}>
        <TextInput
          placeholder={"Search"}
          onChange={() => true}
          defaultValue={""}
        />
      </div>
      <button className={styles.topNavButton}>
        <img src={bellIcon} alt="" />
      </button>
      <button
        className={styles.topNavButton}
        style={{ background: `url('${profileIcon}')` }}
      />
    </div>
  );
}
export default memo(TopNav);