import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";
import TopNav from "../components/TopNav";
import PostAnnouncement from "../cards/PostAnnouncement";
import AnnouncementList from "../cards/AnnouncementList";

export default function AnnouncementsScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          
          <PostAnnouncement
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <AnnouncementList updateTrigger={updateTrigger} setUpdateTrigger={setUpdateTrigger}/>
        </div>
      </div>
    </div>
  );
}
