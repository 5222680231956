import React, { memo } from "react";
import styles from "../styles/components/smallButton.module.scss";

const SmallButton = ({ type, text, onClick }) => {
  const btnStyle =
    type === "primary"
      ? `${styles.primary} ${styles.smallButton}`
      : type === "secondary"
      ? `${styles.secondary} ${styles.smallButton}`
      : type === "danger"
      ? `${styles.danger} ${styles.smallButton}`
      : `${styles.smallButton}`;

  return (
    <button className={btnStyle} onClick={onClick}>
      {text}
    </button>
  );
}

export default memo(SmallButton);
