import React from "react";
import styles from "../styles/components/dropDown.module.scss";

export default function DatePicker({ text, dark, action, defaultValue }) {
  return (
    <div
      className={
        dark
          ? `${styles.darkContainer} ${styles.dropDownContainer}`
          : styles.dropDownContainer
      }
    >
      <input
        type="date"
        id="birthday"
        name="birthday"
        placeholder="Date"
        className={styles.timeSelect}
        style={{ padding: "0 10px" }}
        defaultValue={defaultValue ? defaultValue : undefined}
        onChange={action}
      />
    </div>
  );
}
