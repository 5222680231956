import React from "react";
import styles from "../styles/components/textInput.module.scss";

export default function TextInput({
  onChange,
  defaultValue,
  placeholder,
  customStyles,
}) {
  return (
    <div className={styles.textInputContainer} style={customStyles || {}}>
      <input
        type="text"
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
      />
    </div>
  );
}
