import React, { useEffect, useState } from "react";
import styles from "../styles/cards/showMatchUsers.scss";
import api from "../api/api";
import NewTable from "./Table";
import LoadingAnim from "../components/LoadingAnim";
import SmallButton from "../components/SmallButton";

export default function ShowMatchUsers({
  updateTrigger,
  setUpdateTrigger,
  data,
  type,
}) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState({
    th: [
      "Player",
      "ActivitionID",
      "EaID",
      "XboxId",
      "EpicID",
      "StreetfighterID",
      "PlayStationID",
      "TwitchID",
      "TwitchLive",
      "accountStatus",
    ],
    td: [],
  });
  const [playerList, setPlayerList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let res;
        let mergedArray = [];
        if (type === "TOURNAMENT") {
          res = await api.getTournamentUsersData(data.id);
          mergedArray = res.players.map((player) => player.user);
        } else {
          res = await api.getMatchUsersData(data.id);
          mergedArray = [...res.team1, ...res.team2];
        }
        setPlayerList(mergedArray.map((user) => user._id));
        const newTableData = mergedArray.map((user) => ({
          Player: user?.username || "N/A",
          ActivitionID: user?.activitionId || "N/A",
          EaID: user?.eaId || "N/A",
          XboxId: user?.xboxId || "N/A",
          EpicID: user?.epicId || "N/A",
          StreetfighterID: user?.streetfighterId || "N/A",
          PlayStationID: user?.platstationId || "N/A",
          TwitchID: user?.twitchId || "N/A",
          TwitchLive: user?.twitchLogin
            ? `https://www.twitch.tv/${user?.twitchLogin}`
            : "N/A",
          accountStatus: user?.accountStatus,
        }));

        setTableData({
          th: [...tableData.th],
          td: newTableData,
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching match users data:", error);
      }
    };

    fetchData();
  }, [data]);

  const handleRefund = async () => {
    setLoading(true);
    playerList.forEach(async (userId) => {
      try {
        const res = await api.refundAll({ eventId: data?.id, userId });
      } catch (error) {
        console.log(`Fail to refund - ${userId}`);
      }
    });
    setLoading(false);
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={setUpdateTrigger}>
          Close
        </span>
        <span className="modal-header">
          Players of -{" "}
          {type === "TOURNAMENT" ? data.tournamentId : data.matchId}{" "}
        </span>
        <NewTable tableData={tableData} setTableData={setTableData} />
        <div className="footer">
          <SmallButton type="primary" text="Refund" onClick={handleRefund} />
        </div>
        <LoadingAnim loading={loading} />
      </div>
    </div>
  );
}
