import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/components/pagination.module.scss";

export default function Pagination({
  tableOptions,
  countTotal,
  setTableOptions,
}) {
  const [prevPageActive, setPrevPageActive] = useState(false);
  const [nextPageActive, setNextPageActive] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const paginationRef = useRef(null); // Ref for the pagination container

  const handlePageChange = (page) => {
    tableOptions.page = page;
    setTableOptions({ ...tableOptions });
  };

  const handleNextBtn = () => {
    const pc = Math.ceil(countTotal / tableOptions.limit);
    if (tableOptions.page < pc) {
      tableOptions.page++;
      setTableOptions({ ...tableOptions });
    }
  };

  const handlePrevBtn = () => {
    if (tableOptions.page > 1) {
      tableOptions.page--;
      setTableOptions({ ...tableOptions });
    }
  };

  useEffect(() => {
    const pc = Math.ceil(countTotal / tableOptions.limit);
    if (tableOptions.page < pc) {
      setNextPageActive(true);
    } else {
      setNextPageActive(false);
    }
    if (tableOptions.page > 1) {
      setPrevPageActive(true);
    } else {
      setPrevPageActive(false);
    }
    setPageCount(pc);

    // Scroll to the current page button
    const activeBtn = document.querySelector(`.${styles.activeBtn}`);
    if (activeBtn && paginationRef.current) {
      paginationRef.current.scrollLeft =
        activeBtn.offsetLeft - paginationRef.current.offsetLeft;
    }
  }, [countTotal, tableOptions]);

  return (
    <div className={styles.paginationContainer} ref={paginationRef}>
      <button
        className={styles.prevBtn}
        onClick={handlePrevBtn}
        disabled={!prevPageActive}
      >
        Prev
      </button>
      <div
        className={[pageCount > 35 ? styles.margin : styles.paginationContent]}
      >
        {Array.from({ length: pageCount }, (_, index) => {
          return (
            <button
              key={index}
              className={
                tableOptions.page === index + 1 ? styles.activeBtn : undefined
              }
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          );
        })}
      </div>
      <button
        className={styles.nextBtn}
        onClick={handleNextBtn}
        disabled={!nextPageActive}
      >
        Next
      </button>
    </div>
  );
}
