import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../styles/components/sidebar.module.scss";

import homeIcon from "../assets/images/homeIcon.svg";
import quesionsIcon from "../assets/images/questionsIcon.svg";
import usersIcon from "../assets/images/usersIcon.svg";
import nextIcon from "../assets/images/nextIcon.svg";
import closeIcon from "../assets/images/closeIcon.svg";
import adminIcon from "../assets/images/adminIcon.svg";
import logoutIcon from "../assets/images/logout.svg";
import miscsIcon from "../assets/images/options.svg";
import api from "../api/api";

export default function Sidebar({ setLoggedIn, sidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  const navItems = [
    { icon: homeIcon, title: "Home", path: "/" },
    { icon: quesionsIcon, title: "Matches", path: "/matches" },
    { icon: quesionsIcon, title: "Tournaments", path: "/tournaments" },
    { icon: usersIcon, title: "Users", path: "/users" },
    { icon: usersIcon, title: "Payouts", path: "/payouts" },
    { icon: quesionsIcon, title: "Announcements", path: "/announcements" },
    { icon: quesionsIcon, title: "Complaints", path: "/complaints" },
    { icon: adminIcon, title: "Admins", path: "/admins" },
    { icon: miscsIcon, title: "KYC", path: "/kyc" },
    { icon: miscsIcon, title: "Transactions", path: "/transactions" },
    { icon: miscsIcon, title: "Games", path: "/games" },
    { icon: miscsIcon, title: "Product Categories", path: "/categories" },
    { icon: miscsIcon, title: "Settings", path: "/settings" },
  ];

  const handleSignOut = async () => {
    let text = "Are you sure you want to sign out?";
    // eslint-disable-next-line no-restricted-globals
    if (confirm(text)) {
      const response = await api.logout();
      if (response?.status === 200) {
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setLoggedIn(false);
        navigate("/");
      } else {
        if (!response) {
          console.log("No Sever Response");
        } else if (response?.data) {
          console.log(response.data.message);
        } else {
          console.log(
            "You are currently offline. Check your internet Connection!"
          );
        }
      }
    }
  };

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      let userObj = JSON.parse(user);
      if (userObj.type === "SUPER_ADMIN") {
        setSuperAdmin(true);
      }
    }
  }, []);

  const sidebarStyles =
    isMobile && sidebarOpen
      ? `${styles.sidebarContainer} ${styles.sidebarOpen}`
      : styles.sidebarContainer;

  return (
    <div className={sidebarStyles}>
      {isMobile && (
        <button className={styles.closeBtn} onClick={handleSidebarOpen}>
          <img src={closeIcon} alt="" />
        </button>
      )}
      <div className={styles.logoContainer}>DC Admin</div>
      <div className={styles.navContainer}>
        <div className={styles.navBody}>
          {navItems.map((item, index) => {
            if (item.path === "/admins" && !superAdmin) return null;
            return (
              <Link to={item.path} key={index} onClick={handleSidebarOpen}>
                <div
                  className={
                    path === item.path
                      ? `${styles.navItem} ${styles.active}`
                      : styles.navItem
                  }
                >
                  <div className={styles.navItemIcon}>
                    <img src={item.icon} alt="" />
                  </div>
                  <span className={styles.navItemText}>{item.title}</span>
                  <img src={nextIcon} alt="" className={styles.nextIcon} />
                </div>
              </Link>
            );
          })}
        </div>
        <button className={styles.logoutBtn} onClick={handleSignOut}>
          <img src={logoutIcon} alt="sign out" />
          <span>Sign out</span>
        </button>
      </div>
    </div>
  );
}
