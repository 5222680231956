import React from "react";
import styles from "../styles/components/newTable.module.scss";

export function TableRow({ tr, th }) {
  return (
    <tr className={styles.tr}>
      {Object.keys(tr).map((key, index) => (
        <td key={index} className={styles.td}>
          {tr[key].includes("https") ? (
            <a href={tr[key]} target="_blank" rel="noopener noreferrer">
              {tr[key]}
            </a>
          ) : (
            tr[key]
          )}
        </td>
      ))}
    </tr>
  );
}

export default function NewTable({ tableData, setTableData }) {
  return (
    <div className={styles.tableContainer}>
      <table>
        <thead className={styles.tr}>
          <tr>
            {tableData.th.map((th, index) => (
              <th
                key={index}
                className={
                  index === 1
                    ? `${styles.longThUserTable} ${styles.th}`
                    : styles.th
                }
              >
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.td.map((tr, index) => (
            <TableRow tr={tr} th={tableData.th} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
