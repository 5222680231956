import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";
import TopNav from "../components/TopNav";
import FilterUsers from "../cards/FilterUsers";

export default function UsersScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <FilterUsers
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
      </div>
    </div>
  );
}
