import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LoadingAnim from "../components/LoadingAnim";
import styles from "../styles/cards/graph.module.scss";

import DropDown from "../components/DropDown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function Graph({ graphData, title, handleGraphYearChange }) {
  const [gradient, setGradient] = useState("rgb(255, 255, 255, 0)");
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  const labels = graphData?.labels;

  const years = [
    { text: "2023", value: 2023 },
    { text: "2022", value: 2022 },
    { text: "2021", value: 2021 },
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Subscription",
        data: graphData?.datasets?.subscriptions,
        borderColor: "rgb(53, 87, 204)",
        tension: 0.5,
      },
      {
        label: "Users",
        data: graphData?.datasets?.users,
        fill: true,
        backgroundColor: gradient,
        borderColor: "rgba(75,192,192,1)",
        tension: 0.5,
        borderDashOffset: 1,
        borderDash: [8, 8],
      },
    ],
  };

  const handleYearChage = (e) => {
    handleGraphYearChange(e.target.value);
  };

  useEffect(() => {
    if (!document.querySelector("#canvas")) {
      const canvas = document.createElement("canvas");
      canvas.setAttribute("id", "canvas");
      canvas.style.display = "none";
      document.body.appendChild(canvas);
    }

    var ctx = document.getElementById("canvas").getContext("2d");
    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(249, 253, 250, 1)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    setGradient(gradient);
  }, []);

  useEffect(() => {
    console.log(graphData);
  }, [graphData]);

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={graphData?.loading} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>{title}</span>
        <DropDown options={years} action={handleYearChage} />
      </div>
      <div className={styles.graphContainer}>
        <Line options={options} data={data} />
      </div>
      <div className={styles.graphLegendContainer}>
        <div className={styles.graphLegend}>
          <div className={`${styles.dot} ${styles.greenDot}`} />
          Users
        </div>
        <div className={styles.graphLegend}>
          <div className={`${styles.dot} ${styles.blueDot}`} />
          App Commission
        </div>
      </div>
    </div>
  );
}
