import styles from "../styles/screens/home.module.scss";
import TopNav from "../components/TopNav";
import Settings from "../cards/Settings";

export default function MiscsScreen({ handleSidebarOpen }) {

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <Settings />
        </div>
      </div>
    </div>
  );
}
