import React, { memo, useState } from "react";
import styles from "../styles/components/shortInput.module.scss";

const ImageUpload = ({
  gameId,
  placeholder,
  action,
  disabled,
  imageUrl,
  objectKey,
}) => {
  const [previewImage, setPreviewImage] = useState(imageUrl);
  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setPreviewImage(reader.result);
      action({ file: file, imagePreviewUrl: reader.result, gameId, objectKey });
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className={styles.imageUploadContainer}>
      <label>{placeholder + " :"}</label>
      <img src={previewImage} height={150} width={225} alt="game" />
      <div className={styles.imageUploadWrapper}>
        <input
          type="file"
          onChange={(e) => handleImageChange(e)}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default memo(ImageUpload);
