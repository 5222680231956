import React, { useState } from "react";
import styles from "../styles/pages/dashboard.module.scss";
import Sidebar from "../components/Sidebar";
import UsersScreen from "../screens/Users.screen";

export default function Users({setLoggedIn}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className={styles.wrapper}>
      <Sidebar setLoggedIn={setLoggedIn} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <UsersScreen handleSidebarOpen={handleSidebarOpen} />
    </div>
  );
}
